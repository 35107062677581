import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '../locales/en';
import el from '../locales/el';

Vue.use(VueI18n);

const messages = { el, en };

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
    messages,
});
