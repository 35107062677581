<template>
    <v-dialog
        v-model="showOrgBasicInfo"
        min-width="300"
        max-width="700"
        @click:outside="closeOrgBasicInfoModal"
    >
        <v-card v-if="orgBasicInfoData" class="text-center" shaped elevation="24">
            <v-toolbar
                class="text-h6 font-weight-medium d-flex justify-center"
                style="word-break: break-word;"
                color="primary"
            >
                <span class="white--text">{{ orgBasicInfoData.name }}</span>
            </v-toolbar>
            <v-card-text class="py-4">
                <div>
                    {{ orgBasicInfoData.user_email }}
                </div>
                <div>
                    {{ orgBasicInfoData.address }}
                </div>
                <div>
                    {{ $t('profile.profileSections.infoData.municipality') }}: {{ orgMunicipalityName }}
                </div>
                <div>
                    {{ $t('profile.profileSections.infoData.regionalUnity') }}: {{ orgRegionalUnityName }}
                </div>
                <div class="pb-4">
                    {{ orgBasicInfoData.phone }}
                </div>
                <div v-for="day in orgBasicInfoData.org_working_hours" :key="day.field_id">
                    {{ $t(`profile.profileSections.openingΗours.${day.field_id}`) }}: {{ day.working_hours }}
                </div>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn class="text-none" @click="closeOrgBasicInfoModal">
                    {{ $t('actions.deleteModal.cancel') }}
                </v-btn>

                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import useProfiles from '../../modules/profiles.module';

export default defineComponent({
    setup() {
        const { showOrgBasicInfo, orgBasicInfoData, orgMunicipalityName, orgRegionalUnityName, closeOrgBasicInfoModal } = useProfiles();

        return { showOrgBasicInfo, orgBasicInfoData, orgMunicipalityName, orgRegionalUnityName, closeOrgBasicInfoModal };
    },
});
</script>
