export default {
    path: '/auth',
    name: 'auth',
    meta: {
        isAuthRoute: true,
    },
    login: {
        path: 'login',
        name: 'login',
        meta: {
            isAuthRoute: true,
        },
    },
};
