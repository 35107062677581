import moment from 'moment';
import { MONTH_YEAR_DATE, DAY_MONTH_YEAR_DATE, PILLS_PER_BOX, UNKNOWN_MEDS_UNIT_PRICE, MEASUREMENT_UNIT,
    QUANTITY_TYPES_TOTAL_QUANTITIES_MAP, QUANTITY_TYPES_TOTAL_AVAILABLE_QUANTITIES_MAP,
    MEASUREMENT_UNITS_TOTAL_QUANTITIES_MAP, MEASUREMENT_UNITS_TOTAL_AVAILABLE_QUANTITIES_MAP,
    TOTAL_QUANTITIES_QUANTITY_TYPE_MAP, TOTAL_AVAILABLE_QUANTITIES_QUANTITY_TYPE_MAP } from '../constants';
import useSettings from '../modules/settings.module';

const { getTranslation, getTcTranslation, activeLang } = useSettings();

/** @param {number} ms */
export const waitFor = async(ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

/**
 * @param {string | null} date
 * @returns {boolean | null}
*/
export const aboutToExpire = (date) => {
    const now = new Date();
    const newDate = moment(date, [MONTH_YEAR_DATE, DAY_MONTH_YEAR_DATE]);
    const maxExpirationDate = moment(now, [MONTH_YEAR_DATE, DAY_MONTH_YEAR_DATE]).add(1, 'M');
    return moment(newDate).isBefore(maxExpirationDate);
};

/**
 * @param {string | null} date
 * @returns {boolean}
*/
export const isDateValid = (date) => {
    return moment(date, DAY_MONTH_YEAR_DATE).isValid();
};

/**
 * @param {string | null} date
 * @returns {boolean}
*/
export const isExpired = (date) => {
    const now = new Date();
    const newDate = moment(date, [MONTH_YEAR_DATE, DAY_MONTH_YEAR_DATE]);
    const isDayMonthYearDate = moment(date, DAY_MONTH_YEAR_DATE, true).isValid();
    if (isDayMonthYearDate) {
        const newDatePlusMonth = moment(now, DAY_MONTH_YEAR_DATE).add(1, 'M');
        return moment(newDate).isBefore(newDatePlusMonth);
    }
    return moment(newDate).isBefore(now);
};

/**
 * @param {MedForNgos.DonationOrderResponse} order
 * @returns {string | import('vue-i18n').TranslateResult}
*/
export const getTotalOrderPrice = (order) => {
    let totalOrderPrice = 0;
    let totalOrderMedPrice = 0;
    let totalOrderHygProdPrice = 0;
    let prodWithoutPriceFound = false;

    for (const product of order.products) {
        if (product.org_med) {
            const medPrice = getMedPrice(product.org_med.med_info.price, product.org_med.measurementUnit);

            if (medPrice) {
                totalOrderMedPrice += product.ordered_quantity * medPrice;
            }
        } else if (product.org_hyg_prod) {
            const hygProdPrice = getHygProdPrice(product.org_hyg_prod.hyg_prod_info.price_in_cents);

            if (hygProdPrice) {
                totalOrderHygProdPrice += product.ordered_quantity * hygProdPrice;
            } else {
                prodWithoutPriceFound = true;
            }
        } else {
            continue;
        }
    }
    totalOrderPrice = Math.round(totalOrderMedPrice + totalOrderHygProdPrice);

    return textWithTotalPrice(prodWithoutPriceFound, totalOrderPrice);
};

/**
 * @param {Array<MedForNgos.HistoryOrderedProduct>} products
 * @returns {string | import('vue-i18n').TranslateResult | null}
*/
export const getTotalHistoryOrderPrice = (products) => {
    if (products.length === 0) {
        return null;
    }

    let totalHistoryOrderPrice = 0;
    let totalHistoryMedPrice = 0;
    let totalHistoryHygProdPrice = 0;
    let prodWithoutPriceFound = false;

    for (const product of products) {
        if (product.med_snapshot) {
            const medPrice = getMedPrice(product.med_snapshot.med_info.medPrice, product.med_snapshot.quantity_type);

            if (medPrice) {
                totalHistoryMedPrice += product.ordered_quantity * medPrice;
            }
        } else if (product.hyg_prod_snapshot) {
            const hygProdPrice = getHygProdPrice(product.hyg_prod_snapshot.hyg_prod_info.price_in_cents);

            if (hygProdPrice) {
                totalHistoryHygProdPrice += product.ordered_quantity * hygProdPrice;
            } else {
                prodWithoutPriceFound = true;
            }
        } else {
            continue;
        }
    }
    totalHistoryOrderPrice = Math.round(totalHistoryMedPrice + totalHistoryHygProdPrice);

    return textWithTotalPrice(prodWithoutPriceFound, totalHistoryOrderPrice);
};

/**
 * @param {MedForNgos.HistoryDonationTotalOrderPrices} historyDonationTotalOrderPrices
 * @returns {MedForNgos.totalPrices}
*/
export const getTotalHistoryPrices = (historyDonationTotalOrderPrices) => {
    const totalPrices = {
        total: {
            price: 0,
            isApproximate: false,
        },
        byOrgsOnly: {
            price: 0,
            isApproximate: false,
        },
        byPharmasOnly: {
            price: 0,
            isApproximate: true,
        },
    };

    if (historyDonationTotalOrderPrices.medforngos.meds.is_approximate || historyDonationTotalOrderPrices.medforngos.hyg_prods.is_approximate) {
        totalPrices.total.isApproximate = true;
        totalPrices.byOrgsOnly.isApproximate = true;
    }

    if (historyDonationTotalOrderPrices.pharmas) {
        totalPrices.byPharmasOnly.price = Math.round(historyDonationTotalOrderPrices.pharmas);
        totalPrices.total.price += totalPrices.byPharmasOnly.price;
    }

    totalPrices.byOrgsOnly.price = Math.round(historyDonationTotalOrderPrices.medforngos.meds.total_price + historyDonationTotalOrderPrices.medforngos.hyg_prods.total_price);
    totalPrices.total.price += totalPrices.byOrgsOnly.price;

    return totalPrices;
};

/**
 * @param {number} num
 * @returns {string}
 */
export const getNumberStringRepresentation = num => {
    if ((num === null) || (typeof num === 'undefined')) {
        return '';
    }

    return num.toLocaleString(activeLang.value.numberStringRepresentationLocale);
};

/**
 * @param {number} unitPrice
 * @param {MedForNgos.MeasurementUnit} quantityType
 * @returns {number}
*/
export const getMedPrice = (unitPrice, quantityType) => {
    // @ts-ignore
    if (quantityType === MEASUREMENT_UNIT.Pill) {
        return unitPrice ? unitPrice / PILLS_PER_BOX : UNKNOWN_MEDS_UNIT_PRICE / PILLS_PER_BOX;
    } else {
        // currently it can only be BOX
        return unitPrice ? unitPrice : UNKNOWN_MEDS_UNIT_PRICE;
    }
};

/**
 * @param {number | null} unitPriceInCents
 * @returns {number | null}
*/
export const getHygProdPrice = (unitPriceInCents) => {
    return unitPriceInCents ? unitPriceInCents / 100 : unitPriceInCents;
};

/**
 * @param {boolean} isProdWithoutPrice
 * @param {number} totalPrice
 * @returns {string | import('vue-i18n').TranslateResult}
*/
export const textWithTotalPrice = (isProdWithoutPrice, totalPrice) => {
    return isProdWithoutPrice ?
        getTranslation('labels.approximatePriceAtLeastOneWithoutPrice', { totalPrice })
        : getTranslation('labels.approximatePrice', { totalPrice }) ;
};

/**
 * @param {MedForNgos.HygProdsTotalQuantities} totalQuantities
 * @param {number} quantityChanged
 * @param {MedForNgos.QuantityType} quantityType
 * @returns {MedForNgos.HygProdsTotalQuantities}
*/
export const calculateAndReturnTotalHygProdQuantities = (totalQuantities, quantityChanged, quantityType) => {
    // @ts-ignore
    const totalPropName = QUANTITY_TYPES_TOTAL_QUANTITIES_MAP.get(quantityType);

    return calculateAndReturnTotalQuantities(totalQuantities, totalPropName, quantityChanged);
};

/**
 * @param {MedForNgos.HygProdsToOrderTotalAvailableQuantities} totalQuantities
 * @param {number} quantityChanged
 * @param {MedForNgos.QuantityType} quantityType
 * @returns {MedForNgos.HygProdsToOrderTotalAvailableQuantities}
*/
export const calculateAndReturnTotalHygProdsToOrderAvailableQuantities = (totalQuantities, quantityChanged, quantityType) => {
    // @ts-ignore
    const totalPropName = QUANTITY_TYPES_TOTAL_AVAILABLE_QUANTITIES_MAP.get(quantityType);

    return calculateAndReturnTotalQuantities(totalQuantities, totalPropName, quantityChanged);
};

/**
 * @param {MedForNgos.MedsStorageTotalQuantities} totalQuantities
 * @param {number} quantityChanged
 * @param {MedForNgos.MeasurementUnitValue} measurementUnit
 * @returns {MedForNgos.MedsStorageTotalQuantities}
*/
export const calculateAndReturnTotalMedQuantities = (totalQuantities, quantityChanged, measurementUnit) => {
    const totalPropName = MEASUREMENT_UNITS_TOTAL_QUANTITIES_MAP.get(measurementUnit);

    return calculateAndReturnTotalQuantities(totalQuantities, totalPropName, quantityChanged);
};

/**
 * @param {MedForNgos.MedsToOrderTotalAvailableQuantities} totalQuantities
 * @param {number} quantityChanged
 * @param {MedForNgos.MeasurementUnitValue} measurementUnit
 * @returns {MedForNgos.MedsToOrderTotalAvailableQuantities}
*/
export const calculateAndReturnTotaMedsToOrderAvailableQuantities = (totalQuantities, quantityChanged, measurementUnit) => {
    const totalPropName = MEASUREMENT_UNITS_TOTAL_AVAILABLE_QUANTITIES_MAP.get(measurementUnit);

    return calculateAndReturnTotalQuantities(totalQuantities, totalPropName, quantityChanged);
};

/**
 * @param {any} totalQuantities
 * @param {string | undefined} totalPropName
 * @param {number} quantityChanged
*/
export const calculateAndReturnTotalQuantities = (totalQuantities, totalPropName, quantityChanged) => {
    if (!totalPropName) {
        return totalQuantities;
    }

    // @ts-ignore
    if (totalPropName && totalQuantities[totalPropName]) {
        // @ts-ignore
        totalQuantities[totalPropName] += quantityChanged;
    }

    return totalQuantities;
};

/**
 * @param {any} totalQuantities
 * @param {string | import('vue-i18n').TranslateResult} initalText
*/
export const getHygProdsTotalQuantitiesText = (totalQuantities, initalText) => {
    return createHygProdsTotalQuantitesText(totalQuantities, initalText, TOTAL_QUANTITIES_QUANTITY_TYPE_MAP);
};

/**
 * @param {any} totalQuantities
 * @param {string | import('vue-i18n').TranslateResult} initalText
*/
export const getHygProdsToOrderTotalAvailableQuantitiesText = (totalQuantities, initalText) => {
    return createHygProdsTotalQuantitesText(totalQuantities, initalText, TOTAL_AVAILABLE_QUANTITIES_QUANTITY_TYPE_MAP);
};

/**
 * @param {any} totalQuantities
 * @param {string | import('vue-i18n').TranslateResult} initalText
 * @param {any} map
*/
const createHygProdsTotalQuantitesText = (totalQuantities, initalText, map) => {
    const totalQuantitiesEntries = Object.entries(totalQuantities);
    const filteredTotalQuantitiesEntries = totalQuantitiesEntries.filter(([, value]) => !!value);

    if (filteredTotalQuantitiesEntries.length === 0) {
        return null;
    }

    let mainText = '';
    let counter = 0;

    for (const [key, value] of filteredTotalQuantitiesEntries) {
        const mainTextPart = `<span class="font-weight-bold"> ${getNumberStringRepresentation(value)}</span> ${getTcTranslation(`quantityType.${map.get(key)}`, value)}`;

        if (counter <= 0) {
            mainText += mainTextPart;
        } else {
            mainText += `, ${mainTextPart}`;
        }

        counter++;
    }

    return `${initalText}: ${mainText}`;
};

/**
 * @param {KeyboardEvent} event
 */
export function onlyNumber(event) {
    if (['e', 'E', '+', '-', '.'].includes(event.key)) {
        event.preventDefault();
    }
}
