<template>
    <v-snackbar
        v-model="newAppVersionAvailable"
        multi-line
        bottom
        timeout="-1"
        color="primary"
        elevation="8"
    >
        {{ $t('newAppVersionAvailable.text') }}
        <template #action="{ attrs }">
            <v-btn class="quaternary black--text text-none" text v-bind="attrs" @click="downloadNewAppVersion">
                {{ $t('newAppVersionAvailable.btn') }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';

export default defineComponent({
    setup() {
        const { newAppVersionAvailable, downloadNewAppVersion } = useSettings();

        return {
            newAppVersionAvailable, downloadNewAppVersion,
        };
    },
});
</script>
