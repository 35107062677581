
import { reactive, computed, toRefs } from 'vue';
import { axiosMedForNgos, axiosMedForNgosUninterceptedResponse } from '../utils/axios.util';
import localforage from 'localforage';

import router from '../router';
import authRoutesConfig from '../router/auth/routes.config';
import mainRoutes from '../router/main/routes.config';
import useOrganizations from './organizations.module';
import useOrderTimers from './orderTimers.module';

const { checkAndSetOrderDeletionScheduleOnLogin, endOrderTimer } = useOrderTimers();
const { getOrganizationData } = useOrganizations();

const USERS_STORE_USER_KEY = 'user';
const userStore = localforage.createInstance({
    name: 'UserStore',
});

/** @type {import('vue').UnwrapRef<MedForNgos.UserState>} */
const userState = reactive({
    user: null,
    isLoggedIn: computed(() => !!userState.user?.id),
});

export default function useUsers() {
    const initUserModule = async() => {
        userState.user = await userStore.getItem(USERS_STORE_USER_KEY)
            || userState.user;
    };

    /** @param {LoginData} loginData */
    const login = async(loginData) => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await axiosMedForNgos.post('/login', {
            ...loginData, 'g-recaptcha-response': loginData.gRecaptchaResponse,
        });

        if (!response) {
            return;
        }

        userState.user = response.data;

        await Promise.all([
            userStore.setItem(USERS_STORE_USER_KEY, userState.user),
            getOrganizationData(),
            checkAndSetOrderDeletionScheduleOnLogin(),
        ]);

        const isHospital = useOrganizations().isHospital.value;
        const medsStorageRoute = mainRoutes.storage.meds.name;
        const medNeedsRoute = mainRoutes.needs.meds.name;

        router.push({ name: isHospital ? medNeedsRoute : medsStorageRoute });
    };

    const clearUserState = async() => {
        userState.user = null;
        await userStore.removeItem(USERS_STORE_USER_KEY);
    };

    const logout = async() => {
        /*
            Promise.all() -> It will reject immediately if any of the promises in the array is rejected. If all promises fulfill successfully, it resolves with an array of their results.
            Promise.allSettled() -> It waits for all promises to settle (either fulfill or reject) and then returns an array of objects

            NOTE: We used axiosMedForNgosUninterceptedResponse because if axiosMedForNgos.post('/logout') fails with 403 (due to session expiration for example),
            an infinite call to logout endpoint will take place since this function calls the logout endpoint (which will trigger the interceptor in case of failure)
            and the interceptor will call this function again (in case of 403 error) and so on.
        */

        await Promise.allSettled([
            axiosMedForNgosUninterceptedResponse.post('/logout'),
            endOrderTimer(),
            clearUserState(),
        ]);

        // To avoid redundant navigation to current location
        if (router.currentRoute.name !== authRoutesConfig.login.name) {
            router.push({ name: authRoutesConfig.login.name });
        }
    };

    const refsObject = toRefs(userState);

    return {
        ...refsObject,
        login,
        logout,
        initUserModule,
    };
}

/**
 * @typedef LoginData
 * @prop {string} email
 * @prop {string} password
 * @prop {string} gRecaptchaResponse
 */
