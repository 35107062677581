import { reactive, computed, toRefs } from 'vue';
import { axiosMedForNgos } from '../utils/axios.util';
import { ORG_TYPES } from '../constants';

/** @type {import('vue').UnwrapRef<MedForNgos.OrganizationState>} */
const organizationState = reactive({
    id: null,
    name: null,
    type: null,
    isHospital: false,
    hospitalCanDonate: false,
    freeTransports: 0,
    isAbleToOrder: true,
    minutesToOrder: 0,
    hasBasketOrders: false,
    hasIncomingCompletedPharmaDonations: false,
    isOpenOrganization: computed(() => organizationState.type === ORG_TYPES.Open),
    isCloseOrganization: computed(() => organizationState.type === ORG_TYPES.Close),
    isNotHospitalOrCanDonateHospital: computed(() => !organizationState.isHospital || (organizationState.isHospital && organizationState.hospitalCanDonate)),
});

export default function useOrganizations() {
    const getOrganizationData = async() => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationDataResponse>? } */
        const response = await axiosMedForNgos.get('/organization-data');

        if (!response) {
            return;
        }

        organizationState.id = response.data.id;
        organizationState.name = response.data.name;
        organizationState.type = response.data.type;
        organizationState.isHospital = response.data.is_hospital;
        organizationState.hospitalCanDonate = response.data.hospital_can_donate;
        setFreeTransports(response.data.free_transports);
        organizationState.isAbleToOrder = response.data.is_able_to_order;
        organizationState.minutesToOrder = response.data.minutes_to_order;
        setHasBasketOrders(response.data.has_basket_orders);
        organizationState.hasIncomingCompletedPharmaDonations = response.data.has_incoming_completed_pharma_donations;
    };

    /** @param {number} freeTransportsCount */
    const setFreeTransports = (freeTransportsCount) => {
        organizationState.freeTransports = freeTransportsCount;
    };

    /** @param {boolean} value */
    const setHasBasketOrders = (value) => {
        organizationState.hasBasketOrders = value;
    };

    const refsObject = toRefs(organizationState);

    return {
        ...refsObject,
        getOrganizationData,
        setFreeTransports,
        setHasBasketOrders,
    };
}
