<template>
    <v-snackbar
        v-if="notification.show"
        v-model="notification.show"
        multi-line
        top
        :timeout="getms"
        :color="notification.status === 'ERROR' ? 'red accent-4' : 'success'"
        elevation="8"
        @input="dismissNotification"
    >
        {{ notification.text }}
        <template #action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="dismissNotification">
                <v-icon>{{ ICONS.close }}</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';
import { ICONS } from '../../constants';

export default defineComponent({
    setup() {
        const { notification, dismissNotification } = useSettings();

        return {
            notification, dismissNotification,
        };
    },
    data() {
        return {
            ICONS,
        };
    },
    computed: {
        getms() {
            if (this.notification.status === 'ERROR') {
                return this.notification.ms ?? 6000;
            } else {
                return this.notification.ms ?? 3000;
            }
        },
    },
});
</script>

