import Vue from 'vue';
import router from './router';
import './plugins/axios.plugin';
import vuetify from './plugins/vuetify.plugin';
import i18n from './plugins/i18n.plugin';
import './plugins/mask.plugin';
import App from './App.vue';
import useSettings from './modules/settings.module';
import useUsers from './modules/users.module';
import useOrderTimers from './modules/orderTimers.module';
import useOrganizations from './modules/organizations.module';
import { axiosMedForNgos } from './utils/axios.util';
import { onlyNumber } from './utils/methods.util';

Vue.config.productionTip = false;

Vue.directive('only-number', {
    bind(el) {
        el.addEventListener('keydown', onlyNumber);
    },
    unbind(el) {
        el.removeEventListener('keydown', onlyNumber);
    },
});

const initConfigs = async() => {
    const { initUserModule, isLoggedIn } = useUsers();

    await Promise.all([
        axiosMedForNgos.get('/init'),
        initUserModule(),
        useSettings().initSettingsModule(),
        useOrderTimers().initOrderTimerModule(),
    ]);

    if (isLoggedIn.value) {
        await useOrganizations().getOrganizationData();
    }
};

const initializeApp = async() => {
    await initConfigs();

    Vue.mixin({
        methods: {
        /** @param {MedForNgos.NotificationData} noticationData */
            $notify(noticationData) {
                (useSettings()).notify(noticationData);
            },
        },
    });

    new Vue({
        vuetify,
        router,
        i18n,
        render: h => h(App),
    }).$mount('#app');
};

initializeApp();
