<template>
    <v-app>
        <router-view />

        <generic-notification />

        <organization-basic-info-modal />

        <new-version-available-notification />
    </v-app>
</template>

<script>
import { defineComponent } from 'vue';
import genericNotification from './components/notifications/generic.vue';
import newVersionAvailableNotification from './components/notifications/newVersion.vue';
import OrganizationBasicInfoModal from './components/profile/basicInfoModal.vue';

export default defineComponent({
    components: { genericNotification, OrganizationBasicInfoModal, newVersionAvailableNotification },
});

</script>

<style lang="scss">
@import "./scss/variables";

.delete-card-title {
    background-color: $error !important;
}

.v-toolbar__extension {
    display: flex !important;
    justify-content: center;
}

.border-donations-order-product-type-class {
    border-bottom: 1px solid !important;
    border-top: 1px solid !important;
}

.v-card__title {
    word-break: break-word;
    background-color: $primary;
    color: white;
}

.v-data-table tbody tr:nth-child(odd) {
    background-color: $stripedRowBackground;
}

.v-application .v-btn--disabled,
.v-application .v-icon--disabled,
.v-application .v-input--switch.v-input--is-disabled * {
    cursor: not-allowed !important;
    pointer-events: auto;
}

.border-expansion-panel {
  border-bottom: 3px solid $primary;
}

.v-expansion-panel-header {
  font-weight: bold;
}

.grecaptcha-badge {
    display: none;
}

.theme--light.v-small-dialog__actions, .theme--light.v-small-dialog__menu-content{
    max-width: 450px;
}

.theme--light.v-small-dialog__menu-content, .theme--light.v-small-dialog__actions {
    max-width: 450px;
}

// skeleton-loaders

.v-skeleton-loader__myCustomFilterButton {
    width: 230px;
    height: 60px;
    background: rgba(0, 0, 0, 0.12);
}

.v-skeleton-loader__myCustomText {
    height: 56px;
    background: rgba(0, 0, 0, 0.12);
}

/* Chrome, Safari, Edge, Opera */

.phone-number-input input::-webkit-outer-spin-button,
.phone-number-input input::-webkit-inner-spin-button,
.barcode-number-input input::-webkit-outer-spin-button,
.barcode-number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.phone-number-input input[type=number],
.barcode-number-input input[type=number]{
  -moz-appearance: textfield;
}

/* overwrite download file formats v-select */
.download-file-formats .v-text-field__details{
    display: none;
}
</style>
