import { reactive, computed, toRefs } from 'vue';
import { axiosMedForNgos } from '../utils/axios.util';

/** @type {import('vue').UnwrapRef<MedForNgos.ProfileState>} */
const profileState = reactive({
    mainInfoData: {
        name: '',
        user_email: null,
        number_of_beneficiaries: 0,
        supervisor: '',
        phone: '',
        municipality_data: null,
        description: '',
        website: '',
        address: '',
        show_address_in_public: true,
    },
    workingHoursData: {
        id: null,
        presentation: [],
    },
    cooperationAgreementId: null,
    orgProfileUrl: '/organization-profile/me',
    orgWorkingHoursUrl: computed(() => {
        return '/organization-profile/working-hours';
    }),
    orgWorkingHoursInstanceUrl: computed(() => {
        return `/organization-profile/working-hours/${profileState.workingHoursData.id}`;
    }),
    orgCooperationAgreementsUrl: computed(() => {
        return '/organization-profile/cooperation-agreement';
    }),
    orgCooperationAgreementInstanceUrl: computed(() => {
        return `/organization-profile/cooperation-agreement/${profileState.cooperationAgreementId}`;
    }),
    municipalityName: computed(() => {
        return profileState.mainInfoData.municipality_data?.name ?? '';
    }),
    regionalUnityName: computed(() => {
        return profileState.mainInfoData.municipality_data?.regional_unity.name ?? '';
    }),
});

/** @type {import('vue').UnwrapRef<MedForNgos.organizationBasicInfo>} */
const organizationBasicInfo = reactive({
    showOrgBasicInfo: computed(() => !!organizationBasicInfo.orgBasicInfoData),
    orgBasicInfoData: null,
    orgMunicipalityName: computed(() => {
        return organizationBasicInfo?.orgBasicInfoData?.municipality_data?.name ?? '';
    }),
    orgRegionalUnityName: computed(() => {
        return organizationBasicInfo?.orgBasicInfoData?.municipality_data?.regional_unity.name ?? '';
    }),
});

export default function useProfiles() {
    const initProfileState = async() => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationProfileResponse> } */
        const response = await axiosMedForNgos.get(profileState.orgProfileUrl);

        if (!response) {
            return;
        }

        const { working_hours, org_working_hours, cooperation_agreement, ...mainInfoData } = response.data;

        profileState.mainInfoData = mainInfoData;
        profileState.workingHoursData.id = working_hours;
        profileState.workingHoursData.presentation = org_working_hours;
        profileState.cooperationAgreementId = cooperation_agreement;
    };

    /** @param {MedForNgos.ProfileMainInfoForm} data */
    const setProfileStateMainInfo = async(data) => {
        const response = await axiosMedForNgos.patch(profileState.orgProfileUrl, data);

        if (!response) {
            return;
        }

        profileState.mainInfoData = response.data;

        return response;
    };

    /** @param {MedForNgos.OrganizationWorkingHoursForm} data*/
    const createProfileWorkingHours = async(data) => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationWorkingHoursResponse> } */
        const response = await axiosMedForNgos.post(profileState.orgWorkingHoursUrl, data);

        return workingHoursResponseHandler(response);
    };

    /** @param {MedForNgos.OrganizationWorkingHoursForm} data*/
    const updateProfileWorkingHours = async(data) => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationWorkingHoursResponse> } */
        const response = await axiosMedForNgos.patch(profileState.orgWorkingHoursInstanceUrl, data);

        return workingHoursResponseHandler(response);
    };

    /** @param {import('axios').AxiosResponse<MedForNgos.OrganizationWorkingHoursResponse> } response*/
    const workingHoursResponseHandler = (response) => {
        if (!response) {
            return;
        }

        profileState.workingHoursData.id = response.data.id;
        profileState.workingHoursData.presentation = response.data.working_hours_per_day;

        return response;
    };

    /** @param {MedForNgos.OrganizationCooperationAgreementForm} data*/
    const createProfileCooperationAgreement = async(data) => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationCooperationAgreementResponse> } */
        const response = await axiosMedForNgos.post(profileState.orgCooperationAgreementsUrl, data);

        if (!response) {
            return;
        }

        profileState.cooperationAgreementId = response.data.id;

        return response;
    };

    /** @param {number} organizationId*/
    const openOrgBasicInfoModal = async(organizationId) => {
        /** @type {import('axios').AxiosResponse<MedForNgos.OrganizationBasicInfoResponse> } */
        const response = await axiosMedForNgos.get(`/organization-profile/${organizationId}`);

        if (!response) {
            return;
        }

        organizationBasicInfo.orgBasicInfoData = response.data;
    };

    const closeOrgBasicInfoModal = () => {
        organizationBasicInfo.orgBasicInfoData = null;
    };

    const refsProfileStateObject = toRefs(profileState);
    const refsOrgBasicInfoObject = toRefs(organizationBasicInfo);

    return {
        ...refsProfileStateObject,
        ...refsOrgBasicInfoObject,
        initProfileState,
        setProfileStateMainInfo,
        createProfileWorkingHours,
        updateProfileWorkingHours,
        createProfileCooperationAgreement,
        openOrgBasicInfoModal,
        closeOrgBasicInfoModal,
    };
}
