import { ICONS } from '../../constants';
const storageRoot = 'storage';
const needsRoot = 'needs';

export default {
    path: '/',
    name: 'main',
    storage: {
        path: storageRoot,
        name: 'storage',
        meta: {
            pageTitle: 'storage.pageTitle',
            menu: {
                icon: ICONS.hospitalBox,
                label: 'storage.pageTitle',
            },
        },
        meds : {
            path: `${storageRoot}/meds`,
            name: 'medsStorage',
            meta: {
                pageTitle: 'storage.meds.pageTitle',
                menu: {
                    label: 'storage.meds.pageTitle',
                },
            },
        },
        hygProds : {
            path: `${storageRoot}/hyg-prods`,
            name: 'hygProdsStorage',
            meta: {
                pageTitle: 'storage.hygProds.pageTitle',
                menu: {
                    label: 'storage.hygProds.pageTitle',
                },
            },
        },
        addMeds : {
            path: `${storageRoot}/add-meds`,
            name: 'addMedStorage',
            meta: {
                pageTitle: 'storage.add.addMeds.pageTitle',
            },
        },
        scanMedBarcodeAndAdd: {
            path: `${storageRoot}/scan-med-barcode-and-add`,
            name: 'scanMedBarcodeAndAdd',
            meta: {
                pageTitle: 'storage.add.addMeds.scanMedBarcodeAndAdd.pageTitle',
            },
        },
        addHygProds : {
            path: `${storageRoot}/add-hyg-prods`,
            name: 'addHygProdStorage',
            meta: {
                pageTitle: 'storage.add.addHygProds.pageTitle',
            },
        },
    },
    needs: {
        path: needsRoot,
        name: 'needs',
        meta: {
            pageTitle: 'needs.pageTitle',
            menu: {
                icon: ICONS.bookMultiple,
                label: 'needs.pageTitle',
            },
        },
        meds : {
            path: `${needsRoot}/meds`,
            name: 'medsNeeds',
            meta: {
                pageTitle: 'needs.meds.pageTitle',
                menu: {
                    label: 'needs.meds.pageTitle',
                },
            },
        },
        hygProds : {
            path: `${needsRoot}/hyg-prods`,
            name: 'hygProdsNeeds',
            meta: {
                pageTitle: 'needs.hygProds.pageTitle',
                menu: {
                    label: 'needs.hygProds.pageTitle',
                },
            },
        },
        addMed: {
            path: `${needsRoot}/add-meds`,
            name: 'addMedNeeds',
            meta: {
                pageTitle: 'needs.addMeds.pageTitle',
            },
        },
        addHygProd: {
            path: `${needsRoot}/add-hyg-prods`,
            name: 'addHygProdNeeds',
            meta: {
                pageTitle: 'needs.addHygProds.pageTitle',
            },
        },
    },
    orders: {
        path: 'orders',
        name: 'orders',
        meta: {
            pageTitle: 'order.pageTitle',
            menu: {
                icon: ICONS.shopping,
                label: 'order.pageTitle',
            },
        },
        orderProds: {
            path: 'order-prods',
            name: 'orderProds',
            meta: {
                pageTitle: 'order.prods.pageTitle',
                menu: {
                    label: 'order.prods.menuTitle',
                },
            },
        },
        basket : {
            path: 'basket-orders',
            name: 'basketOrders',
            meta: {
                pageTitle: 'order.basket.pageTitle',
                menu: {
                    label: 'order.basket.pageTitle',
                },
            },
        },
        incoming: {
            path: 'incoming-orders',
            name: 'incomingOrders',
            meta: {
                pageTitle: 'incoming.pageTitle',
                menu: {
                    icon: ICONS.truckPlus,
                    label: 'incoming.pageTitle',
                },
            },
            pending: {
                path: 'incoming-pending-orders',
                name: 'incomingPendingOrders',
                meta: {
                    pageTitle: 'incoming.pendingOrders.pageTitle',
                    menu: {
                        label: 'incoming.pendingOrders.pageTitle',
                    },
                },
            },
            history: {
                path: 'incoming-history-orders',
                name: 'incomingHistoryOrders',
                meta: {
                    pageTitle: 'incoming.historyOrders.pageTitle',
                    menu: {
                        label: 'incoming.historyOrders.pageTitle',
                    },
                },
            },
        },
        outgoing: {
            path: 'outgoing-orders',
            name: 'outgoingOrders',
            meta: {
                pageTitle: 'outgoing.pageTitle',
                menu: {
                    icon: ICONS.handHeart,
                    label: 'outgoing.pageTitle',
                },
            },
            pending: {
                path: 'outgoing-pending-orders',
                name: 'outgoingPendingOrders',
                meta: {
                    pageTitle: 'outgoing.pendingOrders.pageTitle',
                    menu: {
                        label: 'outgoing.pendingOrders.pageTitle',
                    },
                },
            },
            closeOrgPending: {
                path: 'close-org-outgoing-pending-orders',
                name: 'closeOrgOutgoingPendingOrders',
                meta: {
                    pageTitle: 'outgoing.closeOrgPendingOrders.pageTitle',
                    menu: {
                        label: 'outgoing.closeOrgPendingOrders.pageTitle',
                    },
                },
            },
            history: {
                path: 'outgoing-history-orders',
                name: 'outgoingHistoryOrders',
                meta: {
                    pageTitle: 'outgoing.historyOrders.pageTitle',
                    menu: {
                        label: 'outgoing.historyOrders.pageTitle',
                    },
                },
            },
        },
    },
    faq: {
        path: 'faq',
        name: 'faq',
        meta: {
            pageTitle: 'faq.title',
            menu: {
                icon: ICONS.help,
                label: 'faq.title',
            },
        },
    },
    profile:{
        path: 'profile',
        name: 'profile',
        meta: {
            pageTitle: 'profile.pageTitle',
            menu: {
                icon: ICONS.account,
                label: 'profile.pageTitle',
            },
        },
    },
};
