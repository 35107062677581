import { axiosMedForNgos } from '../utils/axios.util';
import { computed, reactive, toRefs } from 'vue';
import useSettings from './settings.module';
import localforage from 'localforage';
import { waitFor } from '../utils/methods.util';
import useOrganizations from './organizations.module';

const { notify } = useSettings();
const { setHasBasketOrders } = useOrganizations();

const ORDER_TIMER_STORE_KEY = 'expirationTimestampInms';
const orderTimerStore = localforage.createInstance({
    name: 'OrderTimerStore',
});

/** @type {import('vue').UnwrapRef<MedForNgos.OrderTimerState>} */
const orderTimerState = reactive({
    msToOrder: 0,
    timeLeftStringRepresentation: computed(() => {
        const minutes = Math.floor(orderTimerState.msToOrder / 60000);
        const seconds = Number(((orderTimerState.msToOrder % 60000) / 1000).toFixed(0));

        return seconds === 60 ? (minutes + 1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }),
    isOrderTimerActive: computed(() => orderTimerState.msToOrder > 0),
});

export default function useOrderTimers() {
    const initOrderTimerModule = async() => {
        const expirationTimestampInms = await orderTimerStore.getItem(ORDER_TIMER_STORE_KEY) || 0;

        if (expirationTimestampInms) {
            setModuleAndRunOrderTimer(expirationTimestampInms);
        }
    };

    const checkAndSetOrderDeletionScheduleOnLogin = async() => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await axiosMedForNgos.get('/basket-order-schedule');

        const expirationTimestampInms = response?.data?.expiration_timestamp || 0;

        if (expirationTimestampInms) {
            await orderTimerStore.setItem(ORDER_TIMER_STORE_KEY, expirationTimestampInms);
            setModuleAndRunOrderTimer(expirationTimestampInms);
        }
    };

    /** @param {number} expirationTimestampInms */
    const setModuleAndRunOrderTimer = (expirationTimestampInms) => {
        orderTimerState.msToOrder = expirationTimestampInms - new Date().getTime();

        if (orderTimerState.isOrderTimerActive) {
            runOrderTimer();
        }
    };

    /** @param {number} minutesToOrder */
    const startOrderTimer = async(minutesToOrder) => {
        const expirationTimestampInms = new Date(
            new Date().getTime() + minutesToOrder * 60000,
        ).getTime();

        await orderTimerStore.setItem(ORDER_TIMER_STORE_KEY, expirationTimestampInms);

        orderTimerState.msToOrder = minutesToOrder * 60000;

        runOrderTimer();
    };

    const runOrderTimer = async() => {
        while (orderTimerState.isOrderTimerActive) {
            orderTimerState.msToOrder = orderTimerState.msToOrder - 1000;

            if (orderTimerState.msToOrder <= 1000 && orderTimerState.isOrderTimerActive) {
                notify({
                    text: 'Τα φάρμακα που είχες στο καλάθι σου διαγράφηκαν. Είναι διαθέσιμα για παραγγελία προς όλους τους χρήστες. \
                        Μπορείς να τα προσθέσεις ξανά στο καλάθι σου, αλλά αυτή την φορά ολοκλήρωσε την παραγγελία εντός του χρονικού ορίου. \
                        Ανανέωσε την σελίδα και ξεκίνα ξανά!',
                    status: 'ERROR',
                    ms: -1,
                });

                endOrderTimer();
            }

            await waitFor(1000);
        }
    };

    const endOrderTimer = async() => {
        orderTimerState.msToOrder = 0;
        // will use setHasBasketOrders here because of timer expiration possibility
        setHasBasketOrders(false);
        await orderTimerStore.removeItem(ORDER_TIMER_STORE_KEY);
    };

    const refsOrderTimerStateObject = toRefs(orderTimerState);

    return {
        ...refsOrderTimerStateObject,
        initOrderTimerModule,
        checkAndSetOrderDeletionScheduleOnLogin,
        startOrderTimer,
        endOrderTimer,
    };
}
