import authConfig from './routes.config';

export default /** @type {import('vue-router').RouteConfig} */ ({
    name: authConfig.name,
    path: authConfig.path,
    meta: authConfig.meta,
    component: () => import('@/views/layouts/auth.layout.vue'),
    children: [
        {
            name: authConfig.login.name,
            path: authConfig.login.path,
            meta: authConfig.login.meta,
            component: () => import('@/views/auth/Login.vue'),
        },
    ],
});
