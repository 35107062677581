import axios from 'axios';
import jsCookie from 'js-cookie';
import axiosRetry from 'axios-retry';

import useUsers from '../modules/users.module';
import useSettings from '../modules/settings.module';

const TIMEOUT_ERROR_CODE = 'ECONNABORTED';

const axiosNonMedForNgosApi = axios.create({});

const axiosMedForNgosOptions = {
    baseURL: process.env.VUE_APP_BASE_API_URL,
    withCredentials: true,
};

// axiosMedForNgosUninterceptedResponse

const axiosMedForNgosUninterceptedResponse = axios.create(axiosMedForNgosOptions);

axiosMedForNgosUninterceptedResponse.interceptors.request.use(config => {
    // @ts-ignore
    const csrfCookieValue = jsCookie.get(process.env.VUE_APP_CSRF_COOKIE_NAME);

    if (csrfCookieValue) {
        config.headers['X-CSRFToken'] = csrfCookieValue;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// axiosMedForNgos

const axiosMedForNgos = axios.create(axiosMedForNgosOptions);

axiosRetry(axiosMedForNgos, { retries: 3 });

axiosMedForNgos.interceptors.request.use(config => {
    // @ts-ignore
    const csrfCookieValue = jsCookie.get(process.env.VUE_APP_CSRF_COOKIE_NAME);

    if (csrfCookieValue) {
        config.headers['X-CSRFToken'] = csrfCookieValue;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

axiosMedForNgos.interceptors.response.use(response => response, async error => {
    const { notify, getTranslation } = useSettings();
    const { logout } = useUsers();

    let errorMessage = '';
    let errorMessageValue = null;
    let translationMessage = null;

    if (error?.response?.status === 400) {
        if (!error?.response?.data?.error_code) {
            errorMessage = 'errorCodes.generalValidationError';
        } else {
            errorMessage = `errorCodes.${error.response.data.error_code}`;
            errorMessageValue = error.response.data?.value ? error.response.data?.value : null;
        }
    } else if (error?.response?.status === 401 || error?.response?.status === 403) {
        errorMessage = 'errorCodes.authenticationFailed';

        await logout();
    } else if (error?.response?.status === 500) {
        errorMessage = 'errorCodes.serverConnectionProblem';
    } else if (error?.code === TIMEOUT_ERROR_CODE) {
        errorMessage = 'errorCodes.timeoutExceeded';
    }

    if (errorMessageValue) {
        translationMessage = getTranslation(errorMessage, { value: errorMessageValue });
    } else {
        translationMessage = getTranslation(errorMessage);
    }

    notify({
        text: translationMessage,
        status: 'ERROR',
    });

    return null;
});

export {
    axiosMedForNgos,
    axiosMedForNgosUninterceptedResponse,
    axiosNonMedForNgosApi,
};
