import Vue from 'vue';
import VueRouter from 'vue-router';

import authRoutes from './auth/routes';
import authConfig from './auth/routes.config';
import mainRoutes from './main/routes';
import mainConfig from './main/routes.config';

import useUsers from '../modules/users.module';

Vue.use(VueRouter);

const routes = [
    authRoutes,
    mainRoutes,
];

routes.push({
    path: '*',
    redirect: { name: mainConfig.needs.meds.name },
});

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async(to, from, next) => {
    const isUserLoggedIn = useUsers().isLoggedIn.value;

    const isAuthRoute = to?.meta?.isAuthRoute ?? false;

    if (isAuthRoute) {
        if (isUserLoggedIn) {
            next({ name: mainConfig.storage.meds.name });
        } else {
            next();
        }
    } else {
        if (isUserLoggedIn) {
            next();
        } else {
            next({ name: authConfig.login.name });
        }
    }
});

export default router;
