import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#6E99D9',
                primaryText: '#528FE9',
                secondary: '#8EACD7',
                tertiary: '#F1F5FA',
                quaternary: '#F4ED46',
                background: '#ECECEC',
                secondaryBackground: '#dfdfdf',
                stripedRowBackground: '#f0f8ff',
                greyBackground: '#BCBEC6',
                orangeLike: '#E66E5A',
                grayText: '#777777',

                accent: '#82B1FF',
                error: '#EC6960',
                info: '#2196F3',
                success: '#4CAF50',
                successIcons:'#EB8670',
            },
            dark: {},
        },
    },
});
