import mainConfig from './routes.config';

export default /** @type {import('vue-router').RouteConfig} */ ({
    name: mainConfig.name,
    path: mainConfig.path,
    component: () => import('@/views/layouts/main.layout.vue'),
    redirect: { name: mainConfig.needs.meds.name },
    children: [
        {
            name: mainConfig.storage.meds.name,
            path: mainConfig.storage.meds.path,
            meta: mainConfig.storage.meds.meta,
            component: () => import('@/views/main/storage/meds/Table.vue'),
        },
        {
            name: mainConfig.storage.hygProds.name,
            path: mainConfig.storage.hygProds.path,
            meta: mainConfig.storage.hygProds.meta,
            component: () => import('@/views/main/storage/hygProds/Table.vue'),
        },
        {
            name: mainConfig.storage.addMeds.name,
            path: mainConfig.storage.addMeds.path,
            meta: mainConfig.storage.addMeds.meta,
            component: () => import('@/views/main/storage/meds/Add.vue'),
        },
        {
            name: mainConfig.storage.addHygProds.name,
            path: mainConfig.storage.addHygProds.path,
            meta: mainConfig.storage.addHygProds.meta,
            component: () => import('@/views/main/storage/hygProds/Add.vue'),
        },
        {
            name: mainConfig.needs.meds.name,
            path: mainConfig.needs.meds.path,
            meta: mainConfig.needs.meds.meta,
            component: () => import('@/views/main/needs/meds/Table.vue'),
        },
        {
            name: mainConfig.needs.hygProds.name,
            path: mainConfig.needs.hygProds.path,
            meta: mainConfig.needs.hygProds.meta,
            component: () => import('@/views/main/needs/hygProds/Table.vue'),
        },
        {
            name: mainConfig.needs.addMed.name,
            path: mainConfig.needs.addMed.path,
            meta: mainConfig.needs.addMed.meta,
            component: () => import('@/views/main/needs/meds/Add.vue'),
        },
        {
            name: mainConfig.storage.scanMedBarcodeAndAdd.name,
            path: mainConfig.storage.scanMedBarcodeAndAdd.path,
            meta: mainConfig.storage.scanMedBarcodeAndAdd.meta,
            component: () => import('@/views/main/storage/meds/ScanMedBarcodeAndAdd.vue'),
        },
        {
            name: mainConfig.needs.addHygProd.name,
            path: mainConfig.needs.addHygProd.path,
            meta: mainConfig.needs.addHygProd.meta,
            component: () => import('@/views/main/needs/hygProds/Add.vue'),
        },
        {
            name: mainConfig.orders.orderProds.name,
            path: mainConfig.orders.orderProds.path,
            meta: mainConfig.orders.orderProds.meta,
            component: () => import('@/views/main/orders/Prods.vue'),
        },
        {
            name: mainConfig.orders.basket.name,
            path: mainConfig.orders.basket.path,
            meta: mainConfig.orders.basket.meta,
            component: () => import('@/views/main/orders/Basket.vue'),
        },
        {
            name: mainConfig.orders.incoming.pending.name,
            path: mainConfig.orders.incoming.pending.path,
            meta: mainConfig.orders.incoming.pending.meta,
            component: () => import('@/views/main/orders/pending/IncomingAsBeneficiary.vue'),
        },
        {
            name: mainConfig.orders.outgoing.pending.name,
            path: mainConfig.orders.outgoing.pending.path,
            meta: mainConfig.orders.outgoing.pending.meta,
            component: () => import('@/views/main/orders/pending/OutgoingAsDonator.vue'),
        },
        {
            name: mainConfig.orders.outgoing.closeOrgPending.name,
            path: mainConfig.orders.outgoing.closeOrgPending.path,
            meta: mainConfig.orders.outgoing.closeOrgPending.meta,
            component: () => import('@/views/main/orders/pending/OutgoingAsDonatorCloseOrg.vue'),
        },
        {
            name: mainConfig.orders.incoming.history.name,
            path: mainConfig.orders.incoming.history.path,
            meta: mainConfig.orders.incoming.history.meta,
            component: () => import('@/views/main/orders/history/IncomingAsBeneficiary.vue'),
        },
        {
            name: mainConfig.orders.outgoing.history.name,
            path: mainConfig.orders.outgoing.history.path,
            meta: mainConfig.orders.outgoing.history.meta,
            component: () => import('@/views/main/orders/history/OutgoingAsDonator.vue'),
        },
        {
            name: mainConfig.faq.name,
            path: mainConfig.faq.path,
            meta: mainConfig.faq.meta,
            component: () => import('@/views/main/Faq.vue'),
        },
        {
            name: mainConfig.profile.name,
            path: mainConfig.profile.path,
            meta: mainConfig.profile.meta,
            component: () => import('@/views/main/Profile.vue'),
        },
    ],
});
