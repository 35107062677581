import { reactive, toRefs } from 'vue';
import localforage from 'localforage';
import workbox from '../registerServiceWorker';
import i18nInstance from '../plugins/i18n.plugin';

const SETTINGS_STORE_ACTIVE_LANG_KEY = 'activeLang';

const settingsStore = localforage.createInstance({
    name: 'SettingsStore',
});

/** @type {MedForNgos.LangSettings} */
const elLang = {
    name: 'Ελληνικά',
    lang: 'el',
    isRtl: false,
    numberStringRepresentationLocale: 'el-GR',
};

/** @type {MedForNgos.LangSettings} */
const enLang = {
    name: 'English',
    lang: 'en',
    isRtl: false,
    numberStringRepresentationLocale: 'en-EN',
};

/** @returns {MedForNgos.NotificationSettings} */
const getNotificationInitialState = () => {
    return {
        show: false,
        status: 'ERROR',
        text: '',
    };
};

/** @type {import('vue').UnwrapRef<MedForNgos.SettingsState>} */
const settingsState = reactive({
    langs: [
        elLang,
        enLang,
    ],
    activeLang: elLang,
    notification: getNotificationInitialState(),
    newAppVersionAvailable: false,
});

export default function useSettings() {
    const initSettingsModule = async() => {
        if (workbox) {
            workbox.addEventListener('waiting', () => {
                console.info('New app version is available for download.');
                settingsState.newAppVersionAvailable = true;
            });
        }

        settingsState.activeLang = await settingsStore.getItem(SETTINGS_STORE_ACTIVE_LANG_KEY)
            || settingsState.activeLang;

        i18nInstance.locale = settingsState.activeLang.lang;
    };

    /** @param {MedForNgos.LangSettings} langSettings */
    const setLang = async(langSettings) => {
        settingsState.activeLang = langSettings;
        i18nInstance.locale = langSettings.lang;

        await settingsStore.setItem(SETTINGS_STORE_ACTIVE_LANG_KEY, langSettings);
    };

    /**
     * @param {string} translation
     * @param {any} [values]
     * @returns {import('vue-i18n').TranslateResult}
    */
    const getTranslation = (translation, values) => {
        const locale = settingsState.activeLang.lang ?? 'el';
        return i18nInstance.t(translation, locale, values);
    };

    /**
     * @param {string} translation
     * @param {number} choice
     * @param {any} [values]
     * @returns {import('vue-i18n').TranslateResult}
    */
    const getTcTranslation = (translation, choice, values) => {
        const locale = settingsState.activeLang.lang ?? 'el';
        return i18nInstance.tc(translation, choice, locale, values);
    };

    /** @param {MedForNgos.NotificationData} notification */
    const notify = (notification) => {
        settingsState.notification.show = true;
        settingsState.notification.text = notification.text;
        settingsState.notification.status = notification?.status ?? 'SUCCESS';
        settingsState.notification.ms = notification?.ms;
    };

    const dismissNotification = () => {
        settingsState.notification = getNotificationInitialState();
    };

    const downloadNewAppVersion = async() => {
        if (!workbox) {
            console.error('Service worker not found on window.');
            return;
        }

        settingsState.newAppVersionAvailable = false;

        await workbox.messageSW({ type: 'SKIP_WAITING' });
    };

    const refsObject = toRefs(settingsState);

    return {
        ...refsObject,
        setLang,
        notify,
        dismissNotification,
        getTranslation,
        getTcTranslation,
        initSettingsModule,
        downloadNewAppVersion,
    };
}

